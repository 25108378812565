import flow from 'lodash/flow';
import isFunction from 'lodash/isFunction';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { setShowPairingSuccessPage } from '../../actions/app';
import { grantDeviceAuth } from '../../actions/auth';
import OkDialog from '../../components/shared/dialog/OkDialog';
import { showPairingSuccessPageCookie } from '../../constants/cookies';
import { AUTH_PAIRINGFLOW_SHOWUPSELLENABLED_PARTNERIDS } from '../../constants/experiments/auth';
import { AUTH_PAIRING_FAILURE_DIALOG_MESSAGE } from '../../constants/localizations/auth';
import { LocationAndLocalizationContext } from '../../providers/LocationAndLocalizationProvider';
import { selectIsSubscriptionEnabled } from '../../selectors/config';
import { selectProducts } from '../../selectors/products';
import {
  hasPairAuthFlowParam,
  shouldSendToSubscribePair,
} from '../../utils/auth/partnerAuthFlowHelpers';
import { setCookie } from '../../utils/cookie';
import { getOauthPartnerId, getUserCodeParam } from '../../utils/queryParams';
import canRenewOrUpdateSubscription from '../../utils/subscription/canRenewOrUpdateSubscription';
import connectWithExperiments from '../connectWithExperiments';
import connectWithAuth from './connectWithAuth';

export default function withAuthHandling(handlers = {}, options = {}) {
  const { authRedirectHandler, unauthRedirectHandler } = handlers;
  const { isPartner } = options;

  return (ProtectedComponent) => {
    class WithAuthHandling extends Component {
      static propTypes = {
        isAuthenticated: PropTypes.bool.isRequired,
        isSubscriptionEnabled: PropTypes.bool,
        products: PropTypes.object,
        history: PropTypes.object.isRequired,
        userDetails: PropTypes.object,
        actions: PropTypes.shape({
          grantDeviceAuth: PropTypes.func,
          setShowPairingSuccessPage: PropTypes.func,
        }),
        canRenewOrUpdateSub: PropTypes.bool.isRequired,
        [AUTH_PAIRINGFLOW_SHOWUPSELLENABLED_PARTNERIDS]: PropTypes.string,
      };

      static contextType = LocationAndLocalizationContext;

      constructor(props) {
        super(props);

        this.state = {
          showFailureDialog: false,
          failureMessage: '',
        };
      }

      openFailureDialog = (failureMessage) => {
        this.setState({ showFailureDialog: true, failureMessage });
      };

      closeFailureDialog = () => {
        this.setState({ showFailureDialog: false, failureMessage: '' });
      };

      async componentDidUpdate(prevProps) {
        const {
          actions,
          isAuthenticated,
          isSubscriptionEnabled,
          products,
          userDetails,
          loginDetails,
          canRenewOrUpdateSub,
          [AUTH_PAIRINGFLOW_SHOWUPSELLENABLED_PARTNERIDS]:
            showUpsellEnabledPartnerIds = '',
        } = this.props;
        const { isAuthenticated: wasAuthenticated } = prevProps;
        const { grantDeviceAuth: grantDeviceAuthAction } = actions;
        const { location } = this.context;
        const { query } = location || {};

        if (isAuthenticated && !wasAuthenticated) {
          const isPairingFlow = isPartner && hasPairAuthFlowParam(query);
          const partnerId = getOauthPartnerId(query);

          const sendToSubscribePair = shouldSendToSubscribePair(
            location,
            isSubscriptionEnabled,
            products,
            canRenewOrUpdateSub,
            { details: userDetails },
          );
          const userCode = getUserCodeParam(query);

          if (isPairingFlow && !sendToSubscribePair) {
            // This conditional represents the flow of a pairingFlow user, who is going direct to /authorize/confirm after auth
            // NOTE: user code is unique to users going through the Auto pairing flow
            // The pairing flow can be extended to non-auto partners (i.e. Sonos), in which case, grantDeviceAuth is not used
            if (userCode) {
              try {
                await grantDeviceAuthAction(userCode);
              } catch (error) {
                return this.openFailureDialog(
                  AUTH_PAIRING_FAILURE_DIALOG_MESSAGE,
                );
              }
            }

            setCookie(showPairingSuccessPageCookie.name, 1);
            actions.setShowPairingSuccessPage(true);
          }

          this.conditionallyReplaceRouteWithDescriptor(
            authRedirectHandler,
            true,
            location,
            {
              userDetails,
              products,
              sendToSubscribePair: isPairingFlow && sendToSubscribePair,
              showUpsellFromConfig:
                showUpsellEnabledPartnerIds.includes(partnerId),
              loginDetails,
            },
          );
        } else if (!isAuthenticated && wasAuthenticated) {
          // Loses authentication
          this.conditionallyReplaceRouteWithDescriptor(
            unauthRedirectHandler,
            false,
            location,
          );
        }
      }

      /**
       *
       * @param {function} handler - Function to get the un/authenticated location descriptor
       * @param {boolean} authFlag - Is the user authenticated?
       * @param {string} location - Location of user
       * @param {object} options - Misc. configurations
       */
      conditionallyReplaceRouteWithDescriptor = (
        handler,
        authFlag,
        location = null,
        handlerOptions = {},
      ) => {
        let locationDescriptor;

        if (isFunction(handler)) {
          locationDescriptor = handler(location, handlerOptions);
          if (locationDescriptor) {
            this.replaceRoute(authFlag, locationDescriptor);
          }
        } else {
          // Refresh current route
          this.replaceRoute(authFlag);
        }
      };

      replaceRoute(authenticated, locationDescriptor) {
        const { location } = this.context;

        this.props.history.replace({
          ...(locationDescriptor || location),
          // NOTE: Needed for the reloadOnPropsChange check in `client/Root.js`
          state: {
            authenticated,
          },
        });
      }

      render() {
        /* eslint-disable no-unused-vars */
        const { failureMessage, showFailureDialog } = this.state;
        const { isAuthenticated, ...otherProps } = this.props;
        const { getLocalizedText } = this.context;

        return (
          <>
            {failureMessage && (
              <OkDialog
                dataTestId="authPageFailureDialog"
                shouldShow={showFailureDialog}
                handleClose={this.closeFailureDialog}
                content={getLocalizedText(failureMessage)}
              />
            )}
            <ProtectedComponent {...otherProps} />
          </>
        );
      }
    }

    const mapStateToProps = (state) => ({
      products: selectProducts(state),
      isSubscriptionEnabled: selectIsSubscriptionEnabled(state),
      canRenewOrUpdateSub: canRenewOrUpdateSubscription(state),
    });

    function mapDispatchToProps(dispatch) {
      return {
        actions: bindActionCreators(
          {
            grantDeviceAuth,
            setShowPairingSuccessPage,
          },
          dispatch,
        ),
      };
    }

    return flow(
      connectWithExperiments([AUTH_PAIRINGFLOW_SHOWUPSELLENABLED_PARTNERIDS]),
      connect(mapStateToProps, mapDispatchToProps),
      connectWithAuth,
      withRouter,
    )(WithAuthHandling);
  };
}
