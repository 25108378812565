/* SUBSCRIPTION QUERY PARAMS */
/*
Used to auto populate coupon input field and
propagate coupon through the subscription flow for reporting
 */
export const COUPON_QUERY_PARAM = 'coupon';

/*
 Used to indicate the source from which the subscription was triggered
 */
export const SOURCE_QUERY_PARAM = 'source';

/*
 Used to indicate the source should apply custom UI/UX
 */
export const CUSTOM_SOURCE_QUERY_PARAM = 'customsource';

/*
 Used to override the hero image on the couponcode landing page
 */
export const SOURCE_IMAGE_URL_QUERY_PARAM = 'sourceimageurl';

/*
 Used to indicate a discount/strike through price should be displayed
 through the subscription flow
 */
export const DISCOUNT_QUERY_PARAM = 'discount';

/*
 In web we use these keys to redirect the user to the content
 they were on previously after they complete the subscription flow.
 We also pass in the guideId to subscribe category action logs.
 We originally implemented as a part of deep linking,
 - may still be used in that context or utm tracking
 see: https://confluence.tunein.com/display/PRODSPECS/Premium+Deep+Link+Query+String+Params
 */
export const BROWSE_QUERY_PARAM = 'browse';

export const PROFILE_QUERY_PARAM = 'profile';

export const NO_PAYMENT_SKU = 'npsku';

export const SKU_QUERY_PARAM = 'sku';
export const SELECTED_SKU_QUERY_PARAM = 'ss';

export const REDIRECT_URI_QUERY_PARAM = 'redirect_uri';

export const IMMEDIATE_REDIRECT_QUERY_PARAM = 'immediate_redirect';

export const TOKEN_REQUIRED_QUERY_PARAM = 'tr';

export const TOKEN_QUERY_PARAM = 'token';

/*
  Used to specify a redirect that should ONLY happen in app
 */
export const IN_APP_REDIRECT = 'in_app_redirect';

/*
 Used to denote that user is in Pairing Auth Flow and specify the flow type (qr | pin)
 */
export const PAIR_AUTH_FLOW = 'pairauthflow';

export const PARTNER_ID = 'partnerid';
export const CLIENT_ID = 'client_id';

/*
 Used to denote a usercode (i.e. pincode) in Pairing Auth Flow
 can appear via a qr generated deeplink or can be manually entered via user as a pincode
 */
export const USER_CODE = 'usercode';

export const BACKGROUND_QUERY_PARAM = 'background';

/*
 Used to note different authentication view states (found in src/common/constants/auth/viewTypes)
 */
export const VIEW_TYPE = 'vt';
export const AUTH_METHOD = 'authmethod';

/*
  Used as a secondary method for attribution.
 */
export const ROKT_ID = 'roktid';

export const TOPIC_ID = 'topicId';

export const MANUAL_DISCORD_MODE = 'discordmode';
export const manualDiscordModes = {
  ui: 'ui',
  mock: 'mock',
};
export const DISCORD_GUILD_ID = 'guild_id';
export const DISCORD_INSTANCE_ID = 'instance_id';
export const DISCORD_CHANNEL_ID = 'channel_id';
export const DISCORD_USER_ID = 'user_id';

export const MANUAL_FORD_MODE = 'fordmode';
export const manualFordModes = {
  ui: 'ui',
};
export const MANUAL_AUTO_MODE = 'automode';
export const manualAutoModes = {
  ui: 'ui',
};
