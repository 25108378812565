import { lotame } from '@tunein/web-common';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { mintSingleton } from '../../client/mint/mintSingleton';
import { logClientError } from '../actions/logging';
import {
  selectGdprApplies,
  selectIsConsentReady,
  selectIsOptedOutOfTargeting,
} from '../selectors/app';
import { selectIsMintInitialized } from '../selectors/mint';
import useActions from './useActions';
import usePrevious from './usePrevious';
import useRouteGuideItem from './useRouteGuideItem';

function useLotameInit(isConsentReady, isMintInitialized, storeGuideItem) {
  const canInitLotame = isConsentReady && isMintInitialized;
  const previousCanInitLotame = usePrevious(canInitLotame);
  const actions = useActions({ logClientError });

  useEffect(() => {
    if (canInitLotame && !previousCanInitLotame) {
      lotame.init({
        onError: actions.logClientError,
        updateLotameAudiences: mintSingleton.instance?.updateLotameAudiences,
      });
      lotame.addInterests(storeGuideItem);
    }
  }, [
    canInitLotame,
    previousCanInitLotame,
    actions.logClientError,
    storeGuideItem,
  ]);
}

function useLotameNavigationListener(
  isConsentReady,
  isMintInitialized,
  storeGuideItem,
  routePropsMatchUrl,
) {
  const prevRoutePropsMatchUrl = usePrevious(routePropsMatchUrl);

  useEffect(() => {
    if (
      isConsentReady &&
      isMintInitialized &&
      prevRoutePropsMatchUrl !== routePropsMatchUrl
    ) {
      lotame.signalPageChange(storeGuideItem);
    }
  }, [
    isConsentReady,
    isMintInitialized,
    prevRoutePropsMatchUrl,
    routePropsMatchUrl,
    storeGuideItem,
  ]);
}

/**
 * Only applies when GDPR is not applicable, as Lotame is TCF-compliant and will interface with the TCF API to
 * obtain GDPR consent data.
 * https://my.lotame.com/t/g9hxvnw/detailed-reference-guide#setconsent
 */
function useLotameConsent(isConsentReady, isMintInitialized) {
  const isGdprApplicable = useSelector(selectGdprApplies);
  const isUserOptedIn = !useSelector(selectIsOptedOutOfTargeting);

  useEffect(() => {
    // must explicitly check for `false` value, as GDPR applicability is determined asynchronously on page load
    if (isConsentReady && isMintInitialized && isGdprApplicable === false) {
      lotame.setConsent(isUserOptedIn);
    }
  }, [isConsentReady, isMintInitialized, isGdprApplicable, isUserOptedIn]);
}

export default function useLotame(routeProps) {
  const routePropsGuideId = routeProps?.guideContext?.guideId;
  const routePropsMatchUrl = routeProps?.matchUrl;
  const isMintInitialized = useSelector(selectIsMintInitialized);
  const storeGuideItem = useRouteGuideItem(routePropsGuideId);
  const isConsentReady = useSelector(selectIsConsentReady);

  useLotameInit(isConsentReady, isMintInitialized, storeGuideItem);
  useLotameNavigationListener(
    isConsentReady,
    isMintInitialized,
    storeGuideItem,
    routePropsMatchUrl,
  );
  useLotameConsent(isConsentReady, isMintInitialized);
}
